import getContrastColor from './getContrastColor'

export default function () {
  const styles = window.formsHostnameConfiguration
    ? window.formsHostnameConfiguration.styles
    : null

  const cssBlocks = []
  if (styles?.highlightColour) {
    cssBlocks.push(`
.btn__formlink:hover {
  background-color: ${styles.highlightColour};
}

.ob-tile__icon-container:hover {
  background-color: ${styles.highlightColour};
}

.ob-loading {
  border-top-color: ${styles.highlightColour};
}

.tag:not(body).is-primary {
  background-color: ${styles.highlightColour};
}

.ob-toast.is-primary {
  background-color: ${styles.highlightColour};
}

.ob-notification.is-primary {
  background-color: ${styles.highlightColour};
}

.button.is-primary,
.button.is-primary[disabled] {
  background-color: ${styles.highlightColour};
}

.button.is-primary:hover {
  background-color: ${styles.highlightColour};
  filter: brightness(95%);
}

.button.is-primary.is-outlined:focus,
.button.is-primary.is-outlined {
  color: ${styles.highlightColour};
  border-color: ${styles.highlightColour};
  background-color: transparent;
}

.button.is-primary.is-outlined.is-loading:focus::after,
.button.is-primary.is-outlined.is-loading::after {
  border-color: transparent transparent ${styles.highlightColour} ${styles.highlightColour} !important;
}

.button.is-primary.is-outlined:hover {
  color: transparent;
  border-color: ${styles.highlightColour};
  background-color: ${styles.highlightColour};
  filter: brightness(100%);
}

.has-thick-border-left.is-primary-thick-border-left {
  border-left-color: ${styles.highlightColour};
}

.ob-drawer__list-item.is-active .ob-drawer__list-item-icon,
.ob-drawer__list-item.is-active .ob-drawer__list-item-text,
.ob-vertical-button.is-active {
  color: ${styles.highlightColour};
}

.badge[data-badge]::after {
  background-color: ${styles.highlightColour};
}

.steps .step-item.is-active .step-marker {
  border-color: ${styles.highlightColour};
  color: ${styles.highlightColour};
}
.steps .step-item.is-completed .step-marker {
  background-color: ${styles.highlightColour};
  border-color: ${styles.highlightColour};
}
.steps .step-item::before {
  background: linear-gradient(to left, #dbdbdb 50%, ${styles.highlightColour} 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}
.steps-header-active-page-icon {
  color: ${styles.highlightColour};
  border-color: ${styles.highlightColour};
}

.ob-number__output.is-dragging {
  background-color: ${styles.highlightColour};
}
.ob-number__output.is-dragging:after {
  border-top-color: ${styles.highlightColour};
}

input[type=range].slider::-webkit-slider-thumb {
  background: ${styles.highlightColour};
  border-color: ${styles.highlightColour};
}
input[type=range].slider::-moz-range-thumb {
  background: ${styles.highlightColour};
  border-color: ${styles.highlightColour};
}
input[type=range].slider::-ms-thumb {
  background: ${styles.highlightColour};
  border-color: ${styles.highlightColour};
}

a.dropdown-item.is-active,
button.dropdown-item.is-active {
  background-color: ${styles.highlightColour};
}

a.ob-bottom-navigation-drop-down-menu__item.is-active,
a.ob-bottom-navigation-drop-down-menu__item.is-active .ob-bottom-navigation-drop-down-menu__icon {
  color: ${styles.highlightColour};
  background-color: white;
}
.has-text-primary {
  color: ${styles.highlightColour} !important;
}
`)
  }

  if (styles?.contrastColour) {
    cssBlocks.push(`
.btn__formlink:hover {
  color: ${styles.contrastColour};
}

.ob-tile__icon-container:hover {
  color: ${styles.contrastColour};
}

.tag:not(body).is-primary {
  color: ${styles.contrastColour};
}

.ob-toast.is-primary {
  color: ${styles.contrastColour};
}

.ob-notification.is-primary {
  color: ${styles.contrastColour};
}

.button.is-primary {
  color: ${styles.contrastColour};
}

.button.is-primary:hover {
  color: ${styles.contrastColour};
}

.button.is-primary.is-outlined:hover {
  color: ${styles.contrastColour};
}

.badge[data-badge]::after {
  color: ${styles.contrastColour};
}

.ob-number__output.is-dragging {
  color: ${styles.contrastColour};
}

a.dropdown-item.is-active:not(.ob-bottom-navigation-drop-down-menu__item),
button.dropdown-item.is-active {
  color: ${styles.contrastColour};
}
`)
  }

  if (styles?.foregroundColour) {
    cssBlocks.push(`
.ob-header-navigation__navbar {
  background-color: ${styles.foregroundColour};
}
.ob-header-navigation__button-icon {
  color: ${styles.foregroundColour};
}
`)

    const headerContrastColor = getContrastColor(styles.foregroundColour)
    cssBlocks.push(`
.ob-header-navigation__app-name {
  color: ${headerContrastColor};
}
.ob-header-navigation__breadcrumb-active-item {
  color: ${headerContrastColor};
}
.ob-header-navigation__breadcrumb-link > a {
  color: ${headerContrastColor};
}
.ob-header-navigation__breadcrumb-chevron-container {
  color: ${headerContrastColor};
}
`)
  }

  if (cssBlocks.length) {
    const style = document.createElement('style')
    style.appendChild(document.createTextNode(cssBlocks.join('')))
    document.head.appendChild(style)
  }

  if (window.formsHostnameConfiguration?.environmentCustomCss) {
    const style = document.createElement('style')
    style.appendChild(
      document.createTextNode(
        window.formsHostnameConfiguration?.environmentCustomCss,
      ),
    )
    document.head.appendChild(style)
  }

  if (styles?.customCss) {
    const style = document.createElement('style')
    style.appendChild(document.createTextNode(styles.customCss))
    document.head.appendChild(style)
  }
}
